.toast-manager {
  position: fixed;
  width: 100%;
  z-index: 9999;
}
.toast-manager .toast-container {
  border: 0px;
  background: none;
  box-shadow: none;
}
.toast-icon {
  width: 1rem;
  height: 1rem;
}

.toast-danger .toast-header {
  background-color: #e40046;
  color: white;
}

.toast-info .toast-header {
  background-color: #009cde;
  color: white;
}

.toast-alert .toast-header {
  background-color: #ffc107;
  color: white;
}

.toast-success .toast-header {
  background-color: #3f9c35;
  color: white;
}
.toast {
  background-color: rgba(255, 255, 255, 0.35);
}
.toast-body {
  background-color: rgba(255, 255, 255, 0.85);
  border: transparent;
}
.notifications-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -3rem;
  left: 1rem;
}
.notifications-button > button {
  background: #e40046 url(../../images/icons/notification-icon.svg) no-repeat
    0.25rem center;
  background-size: 1rem;
  color: #fff;
  border-radius: 1rem;
  font-size: 0.825em;
  font-weight: bold;
  padding: 0 0.5rem 0 1.825em;
}
.notifications-button > button:hover,
.notifications-button > button:active {
  background-color: #990000 !important;
  color: rgba(255, 255, 255, 0.75) !important;
}
.notifications-button > button:after {
  display: none;
}
