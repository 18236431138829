.navbar-vertical-rule {
  height: 36px;
  width: 2px;
}

.header-run-lock-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
