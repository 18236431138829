.chat-tabs {
  margin-bottom: 10px;
  border-bottom: none;
  gap: 8px;
  justify-content: space-between;
}

.chat-tabs .nav-item {
  position: relative;
  padding: 0.15rem 0;
  flex: 1 1 auto;
  min-width: 30px;
}
.chat-tabs .nav-link.has-msg::after {
  display: block;
  content: '';
  border-radius: 50%;
  background: #e40046;
  width: 8.5px;
  height: 8.5px;
  position: absolute;
  top: 3px;
  right: 3px;
}

.chat-tabs .nav-link {
  color: #009cde !important;
  border-color: #e6e6e6 !important;
  border-radius: 5px;
  outline: 2px solid #e6e6e6;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 0;
  width: 100%;
  height: 100%;
}
.chat-tabs .nav-link.active {
  background-color: #e6e6e6;
  outline: 2px solid #009cde;
}
