.radar-container {
  padding: 0 4rem;
}

/*Responsive rules*/
@media (max-width: 991px) {
  .radar-container {
    padding: 0 1rem;
  }
}

@media (max-width: 480px) {
  .radar-container {
    padding: 0 0rem;
  }
}
