.input-triangle-wrapper {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  background: url(../../images/lob-triangle.png) no-repeat center center;
  background-size: 100% 99%;
  width: 290px;
  height: 250px;
  margin: calc(40px + 30px) auto 0 auto;
}

.input-triangle-label {
  position: absolute;
}

.input-triangle-label.as {
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
}
.input-triangle-label.ta {
  bottom: -40px;
  left: -40px;
}
.input-triangle-label.cs {
  bottom: -40px;
  right: -40px;
}

.input-triangle-knob {
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  width: 16px;
  height: 16px;
  box-shadow: 0px 0 10px #00000066;
  cursor: pointer;
}

.tile {
  width: 10px;
  height: 10px;
  flex-shrink: 3;
  border: 1px solid #00000033;
  box-sizing: border-box;
}

.tile.white {
  background-color: #f0f0f000;
}

.tile.black {
  background-color: #0a0a0a99;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

#helper {
  position: absolute;
  z-index: 9999999;
  width: 4px;
  height: 4px;
  border: 2px solid red;
  pointer-events: none;
}
