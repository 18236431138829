.stats-widget-wrapper {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  min-height: 250px;
  height: 100%;
  padding: 1.25rem;
}
.stats-widget-wrapper i {
  width: 50%;
  display: flex;
  margin: 0 auto;
  background: transparent url(../../images/icons/stats-participants-to-date.svg)
    no-repeat center center;
  background-size: cover;
}

.stats-widget-wrapper.tlp i {
  background-image: url(../../images/icons/stats-lifetime-participants.svg);
}
.stats-widget-wrapper.tpd i {
  background-image: url(../../images/icons/stats-participants-to-date.svg);
}
.stats-widget-wrapper.firm i {
  background-image: url(../../images/icons/stats-first-team.svg);
}
.stats-widget-wrapper.hp i {
  background-image: url(../../images/icons/stats-highest-profit.svg);
}
.stats-widget-wrapper.hcs i {
  background-image: url(../../images/icons/stats-highest-client-satisfaction.svg);
}
.stats-widget-wrapper.hes i {
  background-image: url(../../images/icons/stats-highest-employee-satisfaction.svg);
}

.stats-widget-wrapper h1 {
  font-size: 3.125em;

  font-weight: bold;
  margin: 0;
  text-align: left;
}
.stats-container div:nth-child(odd) .stats-widget-wrapper h1 {
  color: #009cde;
}
.stats-container div:nth-child(even) .stats-widget-wrapper h1 {
  color: #3f9c35;
}
.stats-widget-wrapper.firm h1 {
  font-size: 2em;
  margin-top: 0.525em;
}
.stats-widget-wrapper p {
  text-align: left;
  margin: 0;
  padding: 0;
  color: #707070;
}
