.progress-modal {
  width: calc(100vw - 15px);
  max-width: none !important;
}

@media (min-width: 768px) {
  .progress-modal {
    width: 450px;
  }
}
