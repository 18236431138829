@font-face {
  font-family: 'Prelo';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Prelo-Bold.eot');
  src: url('../fonts/Prelo-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Prelo-Bold.woff') format('woff'),
    url('../fonts/Prelo-Bold.ttf') format('truetype'),
    url('../fonts/Prelo-Bold.svg#wf') format('svg');
}

@font-face {
  font-family: 'Prelo';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/Prelo-BoldItalic.eot');
  src: url('../fonts/Prelo-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Prelo-BoldItalic.woff') format('woff'),
    url('../fonts/Prelo-BoldItalic.ttf') format('truetype'),
    url('../fonts/Prelo-BoldItalic.svg#wf') format('svg');
}

@font-face {
  font-family: 'Prelo';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Prelo-Book.eot');
  src: url('../fonts/Prelo-Book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Prelo-Book.woff') format('woff'),
    url('../fonts/Prelo-Book.ttf') format('truetype'),
    url('../fonts/Prelo-Book.svg#wf') format('svg');
}

@font-face {
  font-family: 'Prelo';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Prelo-BookItalic.eot');
  src: url('../fonts/Prelo-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Prelo-BookItalic.woff') format('woff'),
    url('../fonts/Prelo-BookItalic.ttf') format('truetype'),
    url('../fonts/Prelo-BookItalic.svg#wf') format('svg');
}

@font-face {
  font-family: 'Prelo';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/Prelo-Light.eot');
  src: url('../fonts/Prelo-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Prelo-Light.woff') format('woff'),
    url('../fonts/Prelo-Light.ttf') format('truetype'),
    url('../fonts/Prelo-Light.svg#wf') format('svg');
}

/* body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* Control: Input Range */
.input-range--label {
  font-size: 0.875rem;
}
