@import '~bootstrap/scss/functions';

// Override default variables before the import
$font-family-base: 'Prelo', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size-root: 16px;

$enable-negative-margins: true;

// scss-docs-start theme-color-variables
$primary: #009cde;
$secondary: #63666a;
$success: #3f9c35;
$danger: #e40046;
// scss-docs-end theme-color-variables

// scss-docs-start gray-color-variables
$gray-100: #f0f0f0;
$gray-200: #e6e6e6;
$gray-300: #cccccc;
$gray-400: #888b8d;
$gray-500: #707070;
$gray-600: #63666a;
// $gray-800: #515356;
// $gray-900: #424242;
// scss-docs-end gray-color-variables

$body-bg: #fff;
$body-color: $gray-500;

// scss-docs-start navbar-theme-variables
$navbar-light-color: $gray-500;
$navbar-light-hover-color: $gray-600;
$navbar-light-active-color: $gray-600;
$navbar-light-disabled-color: $gray-300;
// $navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
// $navbar-light-toggler-border-color: rgba($black, .1) !default;
// scss-docs-end navbar-theme-variables

// scss-docs-start dropdown-variables
$dropdown-link-color: $gray-500;
$dropdown-link-hover-color: $gray-600;
$dropdown-link-active-color: $gray-600;
$dropdown-link-active-bg: $gray-100;
// scss-docs-end dropdown-variables

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1280px,
);
// scss-docs-end container-max-widths

// scss-docs-start form-range-variables
$form-range-track-bg: $gray-100;
// scss-docs-end form-range-variables

// scss-docs-start form-input-variables
$input-placeholder-color: $gray-300;
$input-border-color: $gray-300;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-border: 0;
$form-check-input-bg: $gray-100;
// scss-docs-end form-check-variables

.form-control {
  &::placeholder {
    font-style: italic;
  }
}

// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-horizontal-width: 250px;
$offcanvas-transition-duration: 0.2s;
// scss-docs-end offcanvas-variables

// Import Bootstrap and its default variables
// @import '~bootstrap/scss/bootstrap';

// Disable smooth scrolling
$enable-smooth-scroll: false;

@import '~bootstrap/scss/variables';

@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';

@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/reboot';
body {
  letter-spacing: 0.045rem;
  overflow-y: scroll;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0.1em;
}
@import '~bootstrap/scss/type';
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  letter-spacing: 0.1em;
}
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
.table {
  & > thead {
    border-style: none;
  }

  & > :not(:first-child),
  & > * {
    border-top: none;
  }

  & > :not(caption) > * > * {
    border-bottom-width: 0;
    padding: 0;
  }

  & tr {
    border-top-style: hidden;
  }
}
@import '~bootstrap/scss/forms';
.form-control {
  &:disabled {
    background-color: #fff;
    cursor: not-allowed;
  }
}
.valid-tooltip {
  background: none;
  color: $success;
  top: 70%;
}
.invalid-tooltip {
  background: none;
  color: $danger;
  top: 70%;
}

@import '~bootstrap/scss/buttons';

// scss-docs-start Button
.btn {
  font-style: italic;
  padding-left: 2rem;
  padding-right: 2rem;
}
@include media-breakpoint-down(sm) {
  .btn {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    &.active,
    &:hover {
      color: #fff;
    }
  }
}
// scss-docs-end Button

@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
.navbar-light {
  height: 64px;
}

.navbar-nav {
  .nav-link {
    display: flex;
    align-items: center;
    color: $gray-500;
    height: 100%;
    letter-spacing: normal;
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    &:hover,
    &:focus {
      color: $gray-600;
      background-color: $gray-100;
    }
  }

  .show > .nav-link,
  .nav-link.active {
    background-color: $gray-100;
  }
}

@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/offcanvas';

@import '~bootstrap/scss/helpers';

// scss-docs-start box-shadow-variables
$box-shadow-rsm: 0 0 6px rgba($black, 0.15);
// scss-docs-end box-shadow-variables

$utilities: map-merge(
  $utilities,
  (
    // scss-docs-start utils-shadow
    'shadow':
      map-merge(
        map-get($utilities, 'shadow'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'shadow'), 'values'),
              (
                plain: $box-shadow-rsm,
              )
            ),
        )
      ),
    // scss-docs-end utils-shadow
    // scss-docs-start utils-color
    'color':
      map-merge(
        map-get($utilities, 'color'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'color'), 'values'),
              (
                'gray-100': $gray-100,
                'gray-200': $gray-200,
                'gray-300': $gray-300,
                'gray-400': $gray-400,
                'gray-500': $gray-500,
                'gray-600': $gray-600,
              )
            ),
        )
      ),
    // scss-docs-end utils-color
    // scss-docs-start utils-bg-color
    'background-color':
      map-merge(
        map-get($utilities, 'background-color'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'background-color'), 'values'),
              (
                'gray-100': $gray-100,
                'gray-200': $gray-200,
                'gray-300': $gray-300,
                'gray-400': $gray-400,
                'gray-500': $gray-500,
                'gray-600': $gray-600,
              )
            ),
        )
      ),
    // scss-docs-end utils-bg-color
    // scss-docs-start utils-letter-spacing
    'letter-spacing':
      (
        property: letter-spacing,
        class: ls,
        values: (
          none: normal,
        ),
      ),
    // scss-docs-end utils-letter-spacing
    'font-size':
      map-merge(
        map-get($utilities, 'font-size'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'font-size'), 'values'),
              (
                7: $font-size-base * 0.875,
              )
            ),
        )
      )
  )
);

@import '~bootstrap/scss/utilities/api';
