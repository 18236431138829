.chat-float-button {
  position: fixed;
  bottom: 3.125em;
  right: 3.125em;
  z-index: 999;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background: #009cde url(../../images/icons/chat_icon.svg) no-repeat center
    center;
  background-size: 100%;
  border: none;
}

.chat-float-button.has-new-msg::after {
  display: block;
  content: '';
  border-radius: 50%;
  background: #e40046;
  width: 8.5px;
  height: 8.5px;
  position: absolute;
  top: 3px;
  right: 3px;
}

.chat-float-panel {
  position: fixed;
  bottom: 7.5em;
  right: 4.5em;
  z-index: 998;
  min-width: 300px;
  height: 480px;
  padding: 10px;
  background: #f0f0f0;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.4));
  border-radius: 12px 12px 0 12px;
  display: none;
}
.chat-float-panel.show {
  display: block;
}

.chat-float-panel::after {
  display: block;
  content: '';
  width: 14px;
  height: 14px;
  border-top: 7px solid #f0f0f0;
  border-right: 7px solid #f0f0f0;
  border-left: 7px solid transparent;
  border-bottom: 7px solid transparent;
  position: absolute;
  bottom: -14px;
  right: 0;
}

.chat-float-panel .chat-float-header {
  padding: 0 10px;
}

.chat-float-panel .chat-float-header h3 {
  font-size: 1.25em;
  font-weight: bold;
  margin: 0;
  color: #009cde;
  width: 100%;
  margin-bottom: 5px;
}

.chat-float-panel .chat-float-header .btn-close {
  vertical-align: middle;
  padding: 0;
  float: right;
  background: transparent url('../../images/icons/cross-icon.svg') center/1em
    auto no-repeat;
  opacity: 1;
  width: 18px;
  height: 18px;
}

.chat-msgs-block,
.chat-new-msg-block {
  position: relative;
  background: #fff;
  border-radius: 6px;
  padding: 15px 10px;
  height: calc(100% - 4rem - 45px);

  overflow-x: hidden;
  overflow-y: auto;
}
.chat-form.float {
  height: 100%;
}
.chat-form.host .chat-msgs-block,
.chat-form.host .chat-new-msg-block {
  border: 1px solid #e6e6e6;
}
.chat-form.host .chat-msgs-block {
  max-height: 490px;
}
.chat-new-msg-block {
  margin-top: 10px;
  height: 4rem;
  padding: 0px;
}

.chat-new-msg-block .chat-input {
  width: 90%;
  height: 100%;
  border: 0;
  padding: 5px 10px 10px;
  margin: 0;
  resize: none;
  overflow-y: hidden;
}
.chat-new-msg-block .chat-input:focus {
  outline: none;
  box-shadow: none;
}

.chat-new-msg-block .btn-snd {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  border-radius: 50%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-new-msg-block .btn-snd:hover {
  background-color: #f0f0f0;
}
.chat-msgs-block .chat-top-area {
  text-align: center;
  font-style: italic;
  font-size: 0.75em;
  line-height: 1;
}

.bubbles-group {
  min-height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: stretch;
  flex-direction: column;
}
.chat-form.host .bubbles-group {
  min-height: 430px;
  margin-top: 10px;
}

.local-bubble,
.contact-bubble {
  position: relative;
  padding: 5px;
  font-size: 0.825em;
  font-style: italic;
  border-radius: 6px;
  background: #f0f0f0;
  color: #63666a;
  margin: 0 25% 10px 0;
}
.local-bubble,
.contact-bubble:last-child {
  margin-bottom: 0;
}

.local-bubble {
  background: #009cde;
  color: #fff;
  margin: 0 0 10px 25%;
}

@media (max-width: 576px) {
  .chat-float-button {
    bottom: 1.125em;
    right: 1.125em;
  }

  .chat-float-panel {
    bottom: 5.5em;
    right: 2.5em;
  }
}
