.home-banner {
  position: relative;
  height: 258px;
  background: #009cde url('../../images/city-assets/skyline-outline.svg')
    no-repeat 100% 80%;
  background-size: auto 76%;
  animation-name: home-skyline-anim;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

.home-banner h1 {
  color: #fff;
  font-size: 3.75em;
  font-weight: bold;
  white-space: nowrap;
}

@keyframes home-skyline-anim {
  from {
    background-position: 102% 80%;
  }
  to {
    background-position: 100% 80%;
  }
}

.home-road-sign {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 65%;
  width: 100%;
  background: transparent url('../../images/city-assets/road-sign.svg')
    no-repeat 100% 100%;
  background-size: auto 100%;
  animation-name: home-road-anim;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@keyframes home-road-anim {
  from {
    background-position: 115% 100%;
  }
  to {
    background-position: 100% 100%;
  }
}

[class*='home-cloud-'] {
  position: absolute;
  animation-duration: 1s;
  animation-iteration-count: 1;
}
.home-cloud-0 {
  width: 92px;
  height: 34px;
  background: url(../../images/city-assets/sky-cloud-1.svg) no-repeat;
  right: 80px;
  top: 30px;
  animation-name: home-cloud-0-anim;
}

.home-cloud-1 {
  width: 125px;
  height: 32px;
  background: url(../../images/city-assets/sky-cloud-0.svg) no-repeat;
  right: 500px;
  top: 45px;
  animation-name: home-cloud-1-anim;
}

@keyframes home-cloud-0-anim {
  from {
    right: 70px;
  }
  to {
    right: 80px;
  }
}

@keyframes home-cloud-1-anim {
  from {
    right: 490px;
  }
  to {
    right: 500px;
  }
}

.login-button {
  border-radius: 50%;
  width: 2rem;
  border: 0;
  padding: 0;
  background-color: transparent !important;
  border-color: transparent !important;
}

.login-button img {
  width: 100%;
  height: auto;
}

/*Responsive rules*/
@media (max-width: 991px) {
  .home-banner {
    height: 300px;
    background-size: auto 68%;
    background-position: 100% 80%;
  }
  .home-banner h1 {
    font-size: 2.75em !important;
  }
  .home-road-sign {
    height: 50%;
  }
}

@media (max-width: 540px) {
  .home-banner {
    height: 300px;
    background-size: auto 60%;
  }

  .home-banner h1 {
    font-size: 2.5em !important;
  }
  .home-road-sign {
    height: 48%;
  }
}

@media (max-width: 480px) {
  .home-banner {
    height: 300px;
    background-size: auto 50%;
    background-position: 100% 92%;
  }
  @keyframes home-skyline-anim {
    from {
      background-position: 102% 92%;
    }
    to {
      background-position: 100% 92%;
    }
  }
  .home-road-sign {
    height: 35%;
  }
}
