.rotateClockwise {
  animation-name: rotateClockwise;
  animation-iteration-count: infinite;
  animation-duration: 0.5s;
  animation-timing-function: linear;
}

@keyframes rotateClockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.host-access-code-text {
  width: 70px;
}

.host-ac-saving-spinner {
  position: absolute;
  top: 50%;
  left: -5px;
  transform: translateY(-50%);
}
