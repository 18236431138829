.skyline {
  background: #009cde
    url('../../images/city-assets/800x350_icon_cityscape_4.png') repeat-x left
    bottom;
  min-height: 486px;
  position: relative;
}

.skyline-cloud-0,
.skyline-cloud-1,
.skyline-cloud-2 {
  position: absolute;
  background: url('../../images/city-assets/sky-cloud-0.svg') no-repeat left top;
  background-size: cover;
}

.skyline-cloud-0 {
  background-image: url('../../images/city-assets/sky-cloud-0.svg');
  width: 15.625rem; /*250px*/
  height: 4.063rem; /*65px*/
  top: 2rem;
  left: 5rem;
}

.skyline-cloud-1 {
  background-image: url('../../images/city-assets/sky-cloud-1.svg');
  width: 15.625rem; /*250px*/
  height: 4.063rem; /*65px*/
  top: 1rem;
  right: 3rem;
}
.skyline-cloud-2 {
  background-image: url('../../images/city-assets/sky-cloud-2.svg');
  width: 15.625rem; /*250px*/
  height: 4.063rem; /*65px*/
  top: 7rem;
  left: 50%;
}

.skyline-help-button {
  position: fixed;
  right: 2rem;
  top: 100px;
  z-index: 10;
}
.skyline-help-button svg path {
  fill: #fff !important;
}
