:root {
  --run-tab-gutter: 9px;
}

.timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0.5rem 0;
}

.timeline-dot {
  background-color: #cccccc;
  border-radius: 50%;
  display: inline-block;
  width: 12px;
  height: 12px;
  position: relative;
}

.timeline-dot .timeline-label {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  letter-spacing: normal;
  position: absolute;
  left: -1rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(1rem);
  min-width: 3rem;
}

.timeline-dot.active {
  background-color: #009cde;
}

.timeline-connector {
  background-color: #fff;
  flex: 1 1;
  border-color: #cccccc;
  border-style: solid;
  border-width: 1px;
  position: relative;
}
.timeline-connector.active {
  border-color: #009cde;
}
.timeline-connector.ready {
  border-style: dashed;
  border-color: #009cde;
}

.timeline-connector .timeline-connector-label {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  letter-spacing: normal;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  transform: translateY(10px);
}

.timeline:hover .timeline-dot.active .timeline-label,
.timeline:hover .timeline-connector .timeline-connector-label {
  opacity: 1;
  visibility: visible;
  transition: 0.5s opacity;
}

.run-tab-group {
  display: flex;
}

.run-tabs {
  padding-left: 0;
  margin-bottom: 3rem;
  margin-top: 1rem;
  transform: translateX(calc(var(--run-tab-gutter) * 6));
}

.run-tab-item {
  height: 44px;
  width: 169px;
}

.run-tab-item.advance {
  transform: translateX(calc(var(--run-tab-gutter) * -6));
  z-index: 1;
}
.run-tab-item.reflect {
  transform: translateX(calc(var(--run-tab-gutter) * -4));
  z-index: 2;
}
.run-tab-item.view {
  transform: translateX(calc(var(--run-tab-gutter) * -3));
  z-index: 3;
}
.run-tab-item.retrieve {
  transform: translateX(calc(var(--run-tab-gutter) * -2));
  z-index: 4;
}
.run-tab-item.execute {
  transform: translateX(calc(var(--run-tab-gutter) * -1));
  z-index: 5;
}
.run-tab-item.bid {
  z-index: 6;
}

.run-tab-button {
  font-style: italic;
  letter-spacing: 1px;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  position: relative;
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
}
.run-tab-button:focus {
  box-shadow: none !important;
}
.run-tab-button:disabled,
.run-tab-button.disabled {
  background: transparent;
  cursor: not-allowed;
}
.run-tab-button.active:disabled > .run-tab-button-label,
.run-tab-button.disabled.active > .run-tab-button-label {
  color: #888b8d !important;
}
.run-tab-button.active,
.run-tab-button:focus,
.run-tab-button:hover {
  background: none;
  color: #fff;
}
.run-tab-button svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.2));
}
.run-tab-button.active > svg polygon,
.run-tab-button:hover > svg polygon,
.run-tab-button:focus > svg polygon,
.run-tab-button.active > svg path,
.run-tab-button:hover > svg path,
.run-tab-button:focus > svg path {
  fill: #009cde !important;
  transition: fill 0.15s ease-in-out;
}
.run-tab-button:disabled > svg polygon,
.run-tab-button.disabled > svg polygon,
.run-tab-button:disabled > svg path,
.run-tab-button.disabled > svg path {
  fill: #f0f0f0 !important;
}
.run-tab-button > .run-tab-button-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.run-tab-button.active > .run-tab-button-label,
.run-tab-button:focus > .run-tab-button-label,
.run-tab-button:hover > .run-tab-button-label {
  background: none;
  color: #fff;
}

table.run-table tr > th,
table.run-table tr > td {
  padding: 0.5rem 1rem;
}

@media (max-width: 1399px) {
  .run-tab-item.view {
    transform: translateX(calc(var(--run-tab-gutter) * 0));
  }
  .run-tab-item.reflect {
    transform: translateX(calc(var(--run-tab-gutter) * -1));
  }
  .run-tab-item.retrieve,
  .run-tab-item.advance {
    transform: translateX(calc(var(--run-tab-gutter) * -3));
  }
}

@media (max-width: 991px) {
  .run-tab-item.retrieve,
  .run-tab-item.reflect {
    transform: translateX(calc(var(--run-tab-gutter) * 0));
  }
  .run-tab-item.advance,
  .run-tab-item.execute,
  .run-tab-item.view {
    transform: translateX(calc(var(--run-tab-gutter) * -2));
  }
}

@media (max-width: 575px) {
  .run-tabs {
    padding: 0 1.5%;
    transform: translateX(calc(var(--run-tab-gutter) * 0));
  }
  li:not(.bid) .run-tab-button-label {
    transform: translate(-50%, -40%);
  }
  .run-tab-item.execute,
  .run-tab-item.retrieve,
  .run-tab-item.view,
  .run-tab-item.reflect,
  .run-tab-item.advance {
    transform: translateX(calc(var(--run-tab-gutter) * 0));
  }
  .run-tab-item.advance {
    transform: translateY(calc(var(--run-tab-gutter) * -3.5));
  }
  .run-tab-item.reflect {
    transform: translateY(calc(var(--run-tab-gutter) * -2.8));
  }
  .run-tab-item.view {
    transform: translateY(calc(var(--run-tab-gutter) * -2.1));
  }
  .run-tab-item.retrieve {
    transform: translateY(calc(var(--run-tab-gutter) * -1.4));
  }
  .run-tab-item.execute {
    transform: translateY(calc(var(--run-tab-gutter) * -0.7));
  }
}
