.accordion-item {
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(240, 240, 240, 0.5);
}

.accordion-header {
  margin-bottom: 0;
}
.accordion-item:nth-child(odd) .accordion-header .accordion-button {
  background: #f0f0f0 !important;
}
.accordion-item:nth-child(even) .accordion-header .accordion-button {
  background: #ffffff !important;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.65rem 1rem 0.65rem 0.65rem;
  font-size: 1rem;
  color: #707070;
  text-align: left;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.accordion-button:after {
  flex-shrink: 0;
  width: 0.674rem;
  height: 0.674rem;
  margin-left: auto;
  content: '';
  background-image: url('../../images/icons/accordion-item-arrow.svg');
  background-repeat: no-repeat;

  transition: transform 0.2s ease-in-out;
}

.accordion-button:not(.collapsed):after {
  transform: rotate(90deg);
}

.accordion-body {
  padding: 1.25rem;
  color: #707070;
}

.help-faq h4 {
  font-size: 1.25em;
}
