.run-advance-box {
  background: linear-gradient(to right, transparent 10%, #f0f0f0);
  position: relative;
}
.run-advance-box::after {
  content: '';
  width: 0;
  height: 0;
  border-top: 32px solid transparent;
  border-left: 15px solid #f0f0f0;
  border-bottom: 32px solid transparent;
  position: absolute;
  right: -15px;
  top: 0;
  bottom: 0;
}

.run-advance-button {
  background-color: #fff;
}
.run-advance-button:active {
  color: #fff;
}
.run-advance-button:hover {
  background-color: #3f9c35;
}
