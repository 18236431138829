:root {
  --pseudo-joint-width: 40px;
  --pseudo-joint-right: -40px;
}

.h-road {
  position: relative;
  width: 100%;
  border-top: 3px solid #63666a;
  border-bottom: 3px solid #63666a;
  height: 82px;
  background: #999999 url('../../images/city-assets/road-bg.svg') repeat-x;
}

.h-road.road-joint-l::before {
  display: block;
  content: ' ';
  height: 82px;
  width: var(--pseudo-joint-width);
  border-top: 3px solid #63666a;
  border-bottom: 3px solid #63666a;
  position: absolute;
  left: var(--pseudo-joint-right);
  top: -3px;
  background: #999999 url('../../images/city-assets/road-bg.svg') repeat-x left
    center;
  background-size: auto 100%;
  z-index: 3;
}

.h-road.road-joint-r::after {
  display: block;
  content: ' ';
  height: 82px;
  width: var(--pseudo-joint-width);
  border-top: 3px solid #63666a;
  border-bottom: 3px solid #63666a;
  position: absolute;
  right: var(--pseudo-joint-right);
  top: -3px;
  background: #999999 url('../../images/city-assets/road-bg.svg') repeat-x right
    center;
  background-size: auto 100%;
  z-index: 3;
}

.grass {
  background: #3f9c35;
  padding: 0;
  position: relative;
}

.leaderboard-billboard {
  position: relative;
  z-index: 2;
  min-height: 450px;
  top: -410px;
  background: #fff;
  border: 4px solid #707070;
  padding: 15px;
}

.leaderboard-billboard:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -32px;
  left: 10%;
  width: 80%;
  height: 32px;
  border-left: 15px solid #707070;
  border-right: 15px solid #707070;
}
.leaderboard-trophy-icon {
  position: absolute;
  left: 0;
  top: 5px;
}
hr.leaderboard-header,
hr.leaderboard-footer1,
hr.leaderboard-footer2 {
  border: none;
  width: 55.85%;
  margin: 1.25rem auto;
  opacity: 1;
}

hr.leaderboard-header {
  border-top: 18px #e6f7ff solid;
}
hr.leaderboard-footer1 {
  background: #aee5ae;
  border: 2px #707070 solid;
  height: 9px;
  margin-bottom: 0;
}
hr.leaderboard-footer2 {
  background: #8cc486;
  border: 2px #707070 solid;
  height: 18px;
  margin-top: 0;
  border-top: transparent;
}

table.leaderboard-bb-table thead th {
  background: #eceeef;
  padding: 10px 0 10px 3px;
}

table.leaderboard-bb-table td {
  padding: 10px 0 10px 3px;
  vertical-align: middle;
}

/*tree assets*/

.tree-2 {
  width: 146px;
  height: 307px;
  background-image: url('../../images/city-assets/tree-0.svg');
  top: 25%;
  right: -15%;
  z-index: 3;
}

.tree-3 {
  width: 107px;
  height: 171px;
  background: url('../../images/city-assets/tree-1.svg');
  top: -60px;
  left: 4%;
}

@media (max-width: 960px) {
  .h-road.road-joint-r::after {
    width: 3px !important;
    right: -3px;
  }
  .h-road.road-joint-l::before {
    width: 3px !important;
    left: -3px;
  }
}

.leaderboard-help-button {
  position: fixed;
  right: 2rem;
  top: 100px;
  z-index: 10;
}
.leaderboard-help-button svg path {
  fill: #fff !important;
}
