.relative {
  position: relative;
}

.explore-welcome-row {
  position: relative;
}
.explore-welcome-row div {
  position: relative;
  height: 140px;
}
.explore-billboard,
.explore-billboard2 {
  position: relative;
  z-index: 2;
  min-height: 420px;
  top: -400px;
  max-width: 500px;
  padding: 15px;
}

.explore-billboard2 {
  max-width: 450px;
  top: -385px;
}
#video-billboard {
  pointer-events: none;
}
#bbv-canvas-hover {
  pointer-events: auto;
  cursor: pointer;
  opacity: 0.75;
}
#bbv-canvas-hover:hover {
  opacity: 1;
}

.v-road {
  position: relative;
  top: -3px;
  height: calc(100% + 6px);
  border-left: 3px solid #63666a;
  border-right: 3px solid #63666a;
  width: 100%;
  max-width: 82px;
  background: #999999 url('../../images/city-assets/road-v-bg.svg') repeat-y;
}

.v-road.road-joint-tl::before,
.v-road.road-joint-tr::before,
.v-road.road-joint-bl::before,
.v-road.road-joint-br::before {
  display: block;
  content: ' ';
  width: 82px;
  max-width: 82px;
  height: 27px;
  position: absolute;
  z-index: 3;
  left: -3px;
}

.v-road.road-joint-tl::before {
  top: -24px;
  background: transparent url('../../images/city-assets/v-road-joint_tl.svg')
    repeat-x left top;
}

.v-road.road-joint-tr::before {
  top: -24px;
  background: transparent url('../../images/city-assets/v-road-joint_tr.svg')
    repeat-x left top;
}

.v-road.road-joint-bl::before {
  bottom: -24px;
  background: transparent url('../../images/city-assets/v-road-joint_bl.svg')
    repeat-x left top;
}

.v-road.road-joint-br::before {
  bottom: -24px;
  background: transparent url('../../images/city-assets/v-road-joint_br.svg')
    repeat-x left top;
}
div[class*='people-'] {
  position: absolute;
  z-index: 4;
}
/* people assets */
.people-0 {
  width: 84px;
  height: 94px;
  background: url('../../images/city-assets/people-0.svg') no-repeat center
    center;
  left: 50%;
  bottom: -50px;
}
.people-1 {
  width: 60px;
  height: 105px;
  background: url('../../images/city-assets/people-1.svg') no-repeat center
    center;
  left: 45%;
  bottom: -50px;
}

/*tree assets*/
div[class*='tree-'] {
  position: absolute;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.tree-0 {
  width: 64px;
  height: 134px;
  background-image: url('../../images/city-assets/tree-0.svg');
  top: -30px;
  left: -10%;
}

.tree-1 {
  width: 90px;
  height: 140px;
  background: url('../../images/city-assets/tree-1.svg');
  top: -60px;
  left: -10%;
}

/*Responsive rules*/
@media (max-width: 960px) {
  .v-road.road-joint-tl::before,
  .v-road.road-joint-tr::before,
  .v-road.road-joint-bl::before,
  .v-road.road-joint-br::before {
    width: 105%;
  }
}

.loading-building {
  position: relative;
  width: 100%;
  padding-bottom: 115%;
  height: auto;
}

.loading-building:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  height: auto;
  min-height: 64px;
  padding-bottom: 100%;
  background-image: url('../../images/city-assets/building-loading.svg');
  background-repeat: no-repeat;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.loading-building span {
  position: absolute;
  width: 100%;
  bottom: 10%;
  color: #fff;
  text-align: center;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*Respondive*/

@media (min-width: 768px) and (max-width: 1100px) {
  .explore-billboard,
  .explore-billboard2 {
    top: -370px;
  }
}
@media (max-width: 767px) {
  .explore-billboard2 {
    top: -320px;
    right: 30px;
  }
}
