.building {
  position: relative;
  width: 100%;
  padding-bottom: 115%;
  height: auto;
}

.building .building-icon {
  position: absolute;
  width: 100%;
  height: auto;
  min-height: 64px;
  padding-bottom: 100%;
  background-size: 300%;
  background-repeat: no-repeat;
}

.building-underconstruction .building-icon {
  background-position: left 0;
}

.building-working .building-icon {
  background-position: center 0;
  cursor: pointer;
  transition: transform 0.25s;
  transform-origin: 50% 100%;
}

@keyframes glowAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
}

.building-working:hover .building-icon {
  transform: scale(1.03);
}
.building-working:hover::before {
  position: absolute;
  z-index: 0;
  top: 10%;
  left: 5%;
  display: block;
  width: 90%;
  height: 100%;
  content: '';
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.75) 0%,
    rgba(255, 255, 255, 0) 60%
  );
  opacity: 0;
  animation-name: glowAnimation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.building-outofbusiness .building-icon {
  background-position: right 0;
}

.building-type-0 .building-icon {
  background-image: url('../../images/city-assets/building-0.svg');
}
.building-type-1 .building-icon {
  background-image: url('../../images/city-assets/building-1.svg');
}
.building-type-2 .building-icon {
  background-image: url('../../images/city-assets/building-2.svg');
}
.building-type-3 .building-icon {
  background-image: url('../../images/city-assets/building-3.svg');
}
.building-type-4 .building-icon {
  background-image: url('../../images/city-assets/building-4.svg');
}
.building-type-5 .building-icon {
  background-image: url('../../images/city-assets/building-5.svg');
}
.building-type-6 .building-icon {
  background-image: url('../../images/city-assets/building-6.svg');
}
.building-type-7 .building-icon {
  background-image: url('../../images/city-assets/building-7.svg');
}
.building-type-8 .building-icon {
  background-image: url('../../images/city-assets/building-8.svg');
}
.building-type-9 .building-icon {
  background-image: url('../../images/city-assets/building-9.svg');
}
.building-type-10 .building-icon {
  background-image: url('../../images/city-assets/building-10.svg');
}
.building-type-11 .building-icon {
  background-image: url('../../images/city-assets/building-11.svg');
}
.building-type-12 .building-icon {
  background-image: url('../../images/city-assets/building-12.svg');
}
.building-type-13 .building-icon {
  background-image: url('../../images/city-assets/building-13.svg');
}
.building-type-14 .building-icon {
  background-image: url('../../images/city-assets/building-14.svg');
}
.building-type-15 .building-icon {
  background-image: url('../../images/city-assets/building-15.svg');
}
.building-type-16 .building-icon {
  background-image: url('../../images/city-assets/building-16.svg');
}
.building-type-17 .building-icon {
  background-image: url('../../images/city-assets/building-17.svg');
}
.building-type-18 .building-icon {
  background-image: url('../../images/city-assets/building-18.svg');
}
.building-type-19 .building-icon {
  background-image: url('../../images/city-assets/building-19.svg');
}
.building-type-20 .building-icon {
  background-image: url('../../images/city-assets/building-20.svg');
}
.building-type-21 .building-icon {
  background-image: url('../../images/city-assets/building-21.svg');
}
.building-type-22 .building-icon {
  background-image: url('../../images/city-assets/building-22.svg');
}
.building-type-23 .building-icon {
  background-image: url('../../images/city-assets/building-23.svg');
}

.building h5 {
  text-align: center;
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.loc-point {
  width: 64px;
  height: 128px;
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  display: none;
}
.loc-point .locationicon-cls-2 {
  width: 64px;
  height: 64px;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}
.loc-point .locationicon-cls-2 {
  fill: #ffbb00;
}
.activeBid .loc-point {
  display: block;
}

.activeBid .loc-point .locationicon-cls-2 {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
.activeBid .loc-point .locationicon-cls-2 {
  fill: #ff9017;
}
.activeBid .loc-point #pin-shadow {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: grow;

  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

.bounce {
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-10px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-0.7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes grow {
  0% {
    transform: scaleX(1) translateY(1.5px);
    opacity: 0.15;
  }
  5% {
    transform: scaleX(1) translateY(1.5px);
    opacity: 1;
  }
  30% {
    transform: scaleX(1) translateY(1.5px);
  }
  50% {
    transform: scaleX(1) translateY(1.5px);
  }
  57% {
    transform: scaleX(1.3) translateY(1.5px);
    opacity: 0.55;
  }
  64% {
    transform: scaleX(2) translateY(1.5px);
    opacity: 0.25;
  }
  100% {
    transform: scaleX(1) translateY(1.5px);
  }
}
/*Respondive*/

@media (min-width: 768px) and (max-width: 1100px) {
  .building h5 {
    font-size: 90%;
    bottom: -1.25rem;
  }
  .loc-point {
    bottom: 1rem;
    right: 1rem;
  }
}
@media (max-width: 767px) {
  .building h5 {
    font-size: 1.25rem;
    bottom: 0;
  }
}
