.advance-year-status-tbl {
  border: 1px solid #e6e6e6;
  border-collapse: separate;
  border-radius: 4px;
  border-spacing: 0;
}

.advance-year-status-tbl td {
  padding: 0.25rem 0.5rem;
}
.advance-year-status-tbl tr > td:nth-child(2) {
  border-left: 1px solid #e6e6e6;
}

.advance-year-status-tbl .status {
  font-style: italic;
}
.advance-year-status-tbl .status.ready {
  color: #3f9c35;
}
.advance-year-status-tbl .status.not-ready {
  color: #cccccc;
}
.advance-year-status-tbl .status > .indicator {
  background-color: #cccccc;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 0.25rem;
}
.advance-year-status-tbl .status.ready > .indicator {
  background-color: #3f9c35;
}
